var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex succesuss"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"justify-center items-center"},[_c('div',{staticClass:"d-theme-dark-bg",staticStyle:{"border-radius":"15px"}},[_c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('JoinTeam'))),_c('o:p')],1)])]),_vm._m(0),_c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#00B050"}},[_vm._v(_vm._s(_vm.$t('P1'))),_c('o:p')],1)])]),_vm._m(1),_c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('P2'))+" "),_c('o:p')],1)]),_vm._m(2),_c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('P3'))),_c('o:p')],1)]),_vm._m(3),_c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('P4'))),_c('o:p')],1)]),_vm._m(4),_c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('P5'))),_c('o:p')],1)]),_vm._m(5),_c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(_vm._s(_vm.$t('SendUSCV'))+" ")]),_vm._m(6),_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" "+_vm._s(_vm.$t('ToBePart'))),_c('o:p')],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%"}},[_vm._v(" ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#1F497D","mso-themecolor":"text2"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{attrs:{"href":"mailto:info@doclinia.com"}},[_c('span',{staticStyle:{"font-size":"12.0pt","line-height":"115%","color":"#00B050"}},[_vm._v("info@doclinia.com")])])])
}]

export { render, staticRenderFns }