<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">


<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('JoinTeam')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%;
color:#00B050">{{$t('P1')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P2')}} <o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P3')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P4')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>
<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('P5')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('SendUSCV')}}&nbsp;</span><span ><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">info@doclinia.com</span></a></span><span  style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">
{{$t('ToBePart')}}<o:p></o:p></span></p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


